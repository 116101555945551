<template>
  <div class="card mb-5">
    <div class="card-header">
      <div class="row align-items-center">
        <div class="col-md-6">
          <h4 class="mb-0 text-primary">
            General information
          </h4>
        </div>
        <cp-edit-buttons
          :edit-mode="isDetailsEditableCurrentBlock"
          :disable-edit="isEditButtonDisabled"
          @changeMode="changeModeProxy"
          @update="saveChanges"
        />
      </div>
    </div>
    <div class="card-body">
      <cp-input-container
        ref="cpInputContainer"
        v-model="model"
        :default-value="getDetailGeneral"
      >
        <div class="row">
          <div class="col-md-6">
            <table class="table table-striped table-borderless mb-0">
              <tbody>
                <tr>
                  <td>
                    Entity name:
                  </td>
                  <td>
                    <cp-input
                      v-if="isDetailsEditableCurrentBlock"
                      name="name"
                      validate="required"
                    />
                    <strong v-else>
                      {{ getDetailGeneral.name }}
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td>
                    Entity email:
                  </td>
                  <td>
                    <cp-input
                      v-if="isDetailsEditableCurrentBlock"
                      name="email"
                      input-type="email"
                      validate="required|email"
                    />
                    <strong v-else>
                      {{ getDetailGeneral.email }}
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td>
                    Country:
                  </td>
                  <td>
                    <cp-select
                      v-if="isDetailsEditableCurrentBlock"
                      :options="countryOptions"
                      name="countryCode"
                      validate="required"
                    />
                    <strong v-else>
                      {{ getCurrentCountryName('countryCode') | nullToMinus }}
                    </strong>
                  </td>
                </tr>
                <tr v-if="model.countryCode === 'US'">
                  <td>
                    State:
                  </td>
                  <td>
                    <cp-select
                      v-if="isDetailsEditableCurrentBlock"
                      :options="stateOptions"
                      name="state"
                      validate="required"
                    />
                    <strong v-else>
                      {{ generalDetailState | nullToMinus }}
                    </strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-md-6">
            <table class="table table-striped table-borderless mb-0">
              <tbody>
                <tr>
                  <td>Address:</td>
                  <td>
                    <cp-input
                      v-if="isDetailsEditableCurrentBlock"
                      name="ownerAddress"
                    />
                    <strong v-else>
                      {{ getDetailGeneral.ownerAddress | nullToMinus }}
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td>Postal Code:</td>
                  <td>
                    <cp-input
                      v-if="isDetailsEditableCurrentBlock"
                      name="zipCode"
                      validate="max:10"
                    />
                    <strong v-else>
                      {{ getDetailGeneral.zipCode | nullToMinus }}
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td>City:</td>
                  <td>
                    <cp-input
                      v-if="isDetailsEditableCurrentBlock"
                      name="city"
                    />
                    <strong v-else>
                      {{ getDetailGeneral.city | nullToMinus }}
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td>Creation date:</td>
                  <td>
                    <strong>
                      {{ getDetailGeneral.createdAt | dateFilter }}
                    </strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </cp-input-container>
    </div>
    <div class="card-header">
      <div class="row align-items-center">
        <div class="col-md-6" />
        <cp-edit-buttons
          :edit-mode="isDetailsEditableCurrentBlock"
          :disable-edit="isEditButtonDisabled"
          @changeMode="changeModeProxy"
          @update="saveChanges"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import states from '~/utilities/us-states-list';

import CpEditButtons from '~/components/common/edit-buttons';
import { CpSelect, CpInput } from '~/components/common/standalone-components/inputs';
import CpInputContainer from '~/components/common/cpInputContainer';
import CpEditableMixin from '~/mixins/editable-invester-details-component';

export default {
  name: 'CpOmnibusWalletOwnerDetailsGeneral',
  components: {
    CpEditButtons,
    CpInputContainer,
    CpSelect,
    CpInput,
  },
  mixins: [
    CpEditableMixin('general', 'omnibusWalletOwner'),
  ],
  data() {
    return {
      states,
      model: {},
    };
  },
  computed: {
    ...mapGetters('omnibusWalletOwner', ['getDetailGeneral']),
    ...mapGetters('global', ['countries', 'countryByCode']),
    countryOptions() {
      return (this.countries || []).map(item => ({
        text: item.name,
        value: item.countryCode,
      }));
    },
    stateOptions() {
      return Object.keys(this.states).map(val => ({ text: this.states[val], value: val }));
    },
    generalDetailState() {
      const currentState = this.stateOptions.find(el => el.value === this.model.state);
      return currentState ? currentState.text : null;
    },
  },
  created() {
    this.changeMode(true);
    this.model = { ...this.getDetailGeneral };
  },
  methods: {
    ...mapActions('omnibusWalletOwner', ['getOmnibusWalletOwnerDetails', 'updateOmnibusWalletOwnerDetailGeneral']),
    changeModeProxy() {
      this.model = {
        ...this.getDetailGeneral,
      };
      this.changeMode();
    },
    getCurrentCountryName(field) {
      const country = this.countryByCode(this.getDetailGeneral[field]);
      return country ? country.name : null;
    },
    saveChanges() {
      if (this.model.countryCode !== 'US') this.model.state = null;

      this.$refs.cpInputContainer.validateForm().then(() => {
        if (this.$refs.cpInputContainer.isValid()) {
          const { idIssuer, detailsId, tokenId } = this.$route.params;
          this.updateOmnibusWalletOwnerDetailGeneral({
            owoId: detailsId,
            tokenId,
            issuerId: idIssuer,
            body: this.model,
          }).then(() => {
            this.changeMode();
            this.model = { ...this.getDetailGeneral };
            this.getOmnibusWalletOwnerDetails({
              owoId: detailsId,
              tokenId,
              issuerId: idIssuer,
            });
          });
        }
      });
    },
  },
};
</script>
