<template>
  <div v-if="isReady">
    <div class="row align-items-center mb-4">
      <div class="col-md-8">
        <b-button
          class="btn btn-outline-primary btn-sm"
          @click="goToList"
        >
          <i class="ion ion-ios-arrow-back" />
          Back to List
        </b-button>
      </div>
      <div class="col-md-4 d-md-flex justify-content-end">
        <span class="text-muted">
          Last update: {{ getDetailGeneral.updatedAt | dateFilter }}
        </span>
      </div>
    </div>
    <cp-error-notices
      v-if="errors.length"
      :errors="errors"
    />
    <div v-else>
      <cp-omnibus-wallet-owner-details-general />
    </div>
  </div>

  <div
    v-else
    class="col-md-12 h-100 text-center"
  >
    <b-spinner class="align-middle" />
    <span class="ml-2">Loading Omnibus Wallet Owner data, please wait..</span>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import CpErrorNotices from '~/components/common/error-notices';
import CpOmnibusWalletOwnerDetailsGeneral from './components/general/index';
import routeNames from '~/utilities/routes';

export default {
  name: 'Details',
  metaInfo: {
    title: 'Details',
  },
  layout: 'main',
  components: {
    CpErrorNotices,
    CpOmnibusWalletOwnerDetailsGeneral,
  },
  data() {
    return {
      errors: [],
      isReady: false,
      queryParams: {
        tokenId: this.$route.params.tokenId,
        issuerId: this.$route.params.idIssuer,
        owoId: +this.$route.params.detailsId,
      },
    };
  },
  computed: {
    ...mapGetters('omnibusWalletOwner', ['getDetailGeneral']),
  },
  watch: {
    $route() {
      this.goToList();
    },
  },
  created() {
    this.getOmnibusWalletOwnerDetails(this.queryParams)
      .catch((err) => {
        this.errors.push((err && err.response && err.response.data && err.response.data.message) || 'Something went wrong.');
      })
      .finally(() => {
        this.isReady = true;
      });
  },
  methods: {
    ...mapActions('omnibusWalletOwner', ['getOmnibusWalletOwnerDetails']),
    goToList() {
      const { params: { idIssuer, tokenId }, query: { from, search } } = this.$route;
      const investorList = from || 'holders';
      this.$router.push(routeNames[investorList](idIssuer, tokenId, search));
    },
  },
};
</script>

<style scoped>

</style>
